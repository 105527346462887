import React, {ChangeEvent, ChangeEventHandler, MutableRefObject} from "react";
import {
    DataGrid,
    GridCallbackDetails, GridCellParams,
    GridColDef, GridColumnHeaderParams, GridColumnVisibilityModel, GridEditMode, GridEventListener,
    GridFilterModel, GridPaginationModel, GridRowHeightParams, GridRowHeightReturnValue,
    GridRowIdGetter, GridRowModesModel, GridRowParams, GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer, MuiEvent
} from "@mui/x-data-grid";
import {Box, Button, MenuItem, Pagination, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import {GridApiCommunity} from "@mui/x-data-grid/internals";
import {AssetScanInfo} from "../assetScan/assetScan.types";
import {EngineInfo} from "../engines/engine.types";
import {SesInfo} from "../ses/ses.types";
import {EsoInfo} from "../eso/eso.types";
import {CustomGridToolbarQuickFilter} from "../muiCustomComponents/CustomGridToolbarQuickFilter";
import {AssetGroupDetails, AssetGroupsInfo} from "../assetGroups/assetGroups.types";
import {PlantnetInfo} from "../plantnet/plantnet.types";
import {ScrInfo} from "../scr/scr.types";
import {EquipmentInfo} from "../installation/installation.types";
import {NetboxInfo, SiteAssetInfo} from "../internalSites/sites.types";
import {PatchValidation} from "../validation/validation.types";
import {WdcuInfo} from "../wdcu/wdcu.types";
import {FieldHistory} from "../history/history.types";
import {PcsInfo} from "../pcs/pcs.types";
import {SwoisInfo} from "../swois/swois.types";
import {AssetDataInfo} from "../assetData/assetData.types";

type rowDataTypes =
    AssetScanInfo[] |
    EngineInfo[] |
    SesInfo[] |
    EsoInfo[] |
    AssetGroupsInfo[] |
    AssetGroupDetails[] |
    PlantnetInfo[] |
    ScrInfo[] |
    EquipmentInfo[] |
    NetboxInfo[] |
    SiteAssetInfo[] |
    PatchValidation[] |
    SwoisInfo[] |
    WdcuInfo[] |
    PcsInfo[] |
    FieldHistory[] |
    AssetDataInfo[] |
    undefined;

interface DataGridProps {
    columnConfig: GridColDef[];
    itemInfo: rowDataTypes;
    columnVisibilityModel?: GridColumnVisibilityModel;
    columnVisibilityModelChanged?: (newVisibilityModel: GridColumnVisibilityModel) => void;
    allowSearch?: boolean;
    allowMultiSelect?: boolean;
    paginationModel?: GridPaginationModel;
    rowCount: number;
    paginationModelChanged?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
    filterModelChanged?: (model: GridFilterModel, details: GridCallbackDetails<"filter">) => void;
    searchButtonClick?: () => void;
    searchEnterKey?: () => void;
    initSearch?: string[] | undefined;
    rowHeight?: ((params: GridRowHeightParams) => GridRowHeightReturnValue) | undefined;
    showAddNewAssetButton?: boolean;
    rowsPerPageOptions?: number[];
    rowPadding?: number,
    onRowModesChange?: (newRowModes: GridRowModesModel) => void;
    rowModes?: GridRowModesModel;
    rowEditStart?: (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => void;
    rowEditStop?: GridEventListener<'rowEditStop'>;
    editMode?: GridEditMode;
    isLoading?: boolean;
    processRowUpdate?: (newRow: any) => void;
    onAddNewAsset?: (event: React.MouseEvent<HTMLElement>) => void;
    cellKeyDown?: (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>, details: GridCallbackDetails) => void;
    apiRef?: MutableRefObject<GridApiCommunity> | undefined;
    onPageChanged?: (event: ChangeEvent<unknown>, page: number) => void;
    onPageSizeChanged?: (event: SelectChangeEvent<number>) => void;
    sortModelChanged?: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
    useFilterHeader?: boolean;
    onColumnSearchChanged?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    columnFilter?: {[k: string]: any};
    blendToTabs?: boolean;
    rowIds?: GridRowIdGetter<any> | undefined;
}

export function CustomDataGrid(
    {
        columnConfig,
        itemInfo,
        columnVisibilityModel,
        columnVisibilityModelChanged,
        allowSearch = true,
        allowMultiSelect = true,
        paginationModel,
        rowCount,
        paginationModelChanged,
        filterModelChanged,
        searchButtonClick,
        searchEnterKey,
        initSearch,
        rowHeight,
        showAddNewAssetButton = false,
        rowsPerPageOptions = [10, 50, 100],
        rowPadding = 4,
        onRowModesChange,
        rowModes,
        rowEditStart,
        rowEditStop,
        editMode,
        isLoading,
        processRowUpdate,
        onAddNewAsset,
        cellKeyDown,
        apiRef,
        onPageChanged,
        onPageSizeChanged,
        sortModelChanged,
        useFilterHeader,
        onColumnSearchChanged,
        columnFilter,
        blendToTabs,
        rowIds,
    }: DataGridProps): JSX.Element {
    const customToolbar = (): JSX.Element => (
        <Box className="gridBlackHeader" sx={{
            svg: {color: "#ffffff"},
            button: {color: "#ffffff"},
            position: "sticky",
            top: 0,
            zIndex: 1,
        }}>
            <Box>
                {showAddNewAssetButton && (
                    <Box style={{ float: "right", display: "inline-block", padding: "8px" }}>
                        <Button disableRipple onClick={onAddNewAsset}>
                            Add New Asset
                        </Button>
                    </Box>
                )}
            </Box>
            {allowSearch && (
                <Box sx={{ p: 1, pl: 1.5, pb: 0 }}>
                    <CustomGridToolbarQuickFilter
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                if (searchEnterKey) {
                                    searchEnterKey()
                                }
                            }
                        }}
                        initialSearchVal={initSearch === undefined ? "" : initSearch.join(' ')}
                    />
                    <Button
                        variant="text"
                        disableRipple
                        onClick={() => {
                            if (searchButtonClick) {
                                searchButtonClick()
                            }
                        }}
                    >
                        Search
                    </Button>
                </Box>
            )}
            <Box sx={{
                pl: 0.5
            }}>
                <GridToolbarContainer>
                    <GridToolbarColumnsButton
                        disableRipple
                        style={{
                        fontSize: 12,
                    }}/>
                </GridToolbarContainer>
            </Box>
        </Box>
    )

    let pageLow = 0;
    let pageHigh = 0;

    if(paginationModel !== null && paginationModel !== undefined) {
        pageLow = paginationModel.pageSize * paginationModel.page;
        pageHigh = pageLow + paginationModel.pageSize;
    }

    const customPagination = (): JSX.Element => (
        <Box>
            {paginationModel !== null && paginationModel !== undefined &&
                <Box>
                    {rowCount !== 0 && <Typography style={{
                        float: "left",
                        fontSize: 14,
                        paddingTop: "3px",
                        paddingRight: "32px",
                    }}>
                        Showing {pageLow + 1} - {pageHigh > rowCount ? rowCount : pageHigh} of {rowCount} items
                    </Typography>}
                    <Typography style={{
                        float: "left",
                        fontSize: 14,
                        paddingTop: "3px",
                        paddingRight: "9px",
                    }}>
                        Rows per page:
                    </Typography>
                        <Select
                            sx={{
                                '& .MuiSelect-select': {
                                    p: "3px 0px 2px 7px",
                                }
                            }}
                            size="small"
                            variant="outlined"
                            value={paginationModel.pageSize}
                            onChange={onPageSizeChanged}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                        <Pagination
                            style={{ float: "right" }}
                            page={paginationModel.page + 1}
                            count={Math.ceil(rowCount / paginationModel.pageSize)}
                            onChange={onPageChanged}
                        />
                </Box>
            }
        </Box>
    )

    // eslint-disable-next-line arrow-body-style
    const defaultFilterHeader = (params: GridColumnHeaderParams): JSX.Element => {
        const item: string | undefined = columnFilter === undefined ? undefined : columnFilter[params.field];

        return(
            <Box display="flex" flexDirection="column" sx={{ justifyContent: 'space-between' }}
              style={{
                marginBottom: 10,
                position: "sticky",
                top: 0,
                zIndex: 1,
                height: "100%",
            }}>
                <Typography
                    display="flex"
                    alignItems="flex-end"
                    style={{
                        whiteSpace: "pre-line",
                        fontWeight: "normal",
                        fontSize: 16,
                        marginBottom: 3,
                        height: "48px"
                    }}
                >
                    {params.colDef.headerName}
                </Typography>
                <TextField
                    id={params.field}
                    value={(item !== undefined && item !== "") ? item : ""}
                    label={item !== undefined && item !== "" ? "" : "Search..."}
                    size="small"
                    InputLabelProps={{shrink: false}}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    style={{
                        minWidth: params.colDef.minWidth !== undefined ? params.colDef.minWidth - 65 : 140,
                        backgroundColor: "#ffffff",
                        borderRadius: "4px",
                    }}
                    sx={{
                        '&.MuiTextField-root': {
                            marginBottom: "7px",
                        },
                        svg: {
                            color: "#ffffff"
                        },
                        input: {
                            color: "#000000",
                            fontFamily: "Noto Sans",
                            fontSize: 14,
                            fontWeight: "lighter",
                        },
                        label: {
                            color: "#cccccc",
                            fontFamily: "Noto Sans",
                            fontSize: 16,
                            fontWeight: "lighter",
                        }
                    }}
                    onChange={onColumnSearchChanged}
                />
            </Box>
        );
    }

    // eslint-disable-next-line arrow-body-style
    const defaultHeader = (params: GridColumnHeaderParams): JSX.Element => {
        return(
            <Box sx={{
                svg: {color: "#ffffff"},
                position: "sticky",
                top: 0,
                zIndex: 1,
            }}>
                <Typography style={{ fontWeight: "normal", fontSize: 16 }}>{params.colDef.headerName}</Typography>
            </Box>
        );
    }

    for(let i = 0; i < columnConfig.length; i += 1) {
        // eslint-disable-next-line no-continue
        if(columnConfig[i].field === "__check__" || columnConfig[i].field === "actions") continue;

        // eslint-disable-next-line no-param-reassign
        columnConfig[i].renderHeader = useFilterHeader ? defaultFilterHeader : defaultHeader
    }

    return(
        <DataGrid
            autoHeight
            apiRef={apiRef}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={columnVisibilityModelChanged}
            sx={{
                '.MuiDataGrid-footerContainer': {
                    borderColor: "#0f172b",
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    position: "sticky",
                    bottom: 0,
                    alignSelf: "flex-end",
                    backgroundColor: "#ffffff",
                    width: "100%",
                },
                '.MuiDataGrid-columnHeaders': {
                    position: "sticky",
                    top: useFilterHeader ? "33px": "77px",
                    zIndex: 1,
                },
                '.MuiDataGrid-main': {
                    overflow: "unset",
                },
                '.MuiDataGrid-columnHeaderTitleContainer': {color: "#ffffff", svg: {color: "#ffffff"}},
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: `${rowPadding}px` },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: `${rowPadding * 2}px` },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: `${rowPadding * 3}px` },
                '& .MuiDataGrid-cell': {
                    borderBottom: '1px solid #484848',
                },
                '& .MuiPaginationItem-root': {
                    borderRadius: 2,
                },
                '.MuiDataGrid-columnHeaderTitleContainerContent': {height: '100%'},
                '.MuiDataGrid-columnHeaderTitle': { fontWeight: "normal", fontSize: 16 },
                '.MuiDataGrid-row.Mui-odd': { backgroundColor: "#f8f8f8" },
                '.MuiDataGrid-row.Mui-even': { backgroundColor: "#ffffff" },
                '& .MuiDataGrid-row:hover': { backgroundColor: "#ececec" },
                '& .MuiDataGrid-iconButtonContainer': {
                    marginLeft: '2px',
                    visibility: 'visible !important',
                    width: 'auto !important',
                },
                borderColor: "#0f172b",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                marginLeft: blendToTabs ? "7px" : 0,
                marginRight: blendToTabs ? "7px" : 0,
            }}
            style={{
                fontFamily: "Noto Sans",
                fontSize: 16,
                fontWeight: "lighter",
                clear: "both",
                minWidth: "850px",
            }}
            columns={columnConfig}
            rows={itemInfo !== undefined ? itemInfo : []}
            rowCount={rowCount}
            disableColumnMenu
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            paginationModel={paginationModel}
            pageSizeOptions={rowsPerPageOptions}
            onSortModelChange={sortModelChanged}
            slots={{
                toolbar: customToolbar,
                pagination: customPagination
            }}
            loading={isLoading}
            rowModesModel={rowModes}
            getRowId={rowIds === undefined ? (row: any) => row.object_id : rowIds}
            getRowHeight={rowHeight}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            checkboxSelection={allowMultiSelect}
            onPaginationModelChange={paginationModelChanged}
            onFilterModelChange={filterModelChanged}
            onRowModesModelChange={onRowModesChange}
            onRowEditStart={rowEditStart}
            onRowEditStop={rowEditStop}
            editMode={editMode}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
                console.error(error)
            }}
            onCellKeyDown={cellKeyDown}
            columnHeaderHeight={useFilterHeader ? 92 : 50}
        />
    );
}
