import {useNavigate} from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    ListSubheader,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import dayjs from 'dayjs';
import { DatePicker, DateView, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, {MouseEventHandler, useEffect, useMemo, useRef, useState} from "react";
import HistoryIcon from '@mui/icons-material/History';
import {DataGrid} from "@mui/x-data-grid";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DropDownItem } from "../assetGroups/assetGroups.types";
import {AuthenticateRolesLocally, RoleAuthResult} from "../auth/rolesAuth";
import {FieldHistorySet} from "../history/history.types";
import {useClickOutside} from "../misc.hooks";

interface BackButtonProps {
    updating: boolean;
}

interface UploadStatusMessageProps {
    status: string;
}

interface CancelButtonProps {
    editing: boolean;
    updating: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>
}

interface InfoFieldPairProps {
    title: string;
    name: string;
    idPrefix?: string;
    data: any;
    multiline?: boolean;
    editing: boolean;
    updating: boolean;
    editable?: boolean;
    mandatory?: boolean;
    historyData?: FieldHistorySet | undefined;
    objectId?: string;
    source?: string;
    assetName?: string;
    showDateWarning?: boolean;
}

interface InfoDropdownPairProps {
    title: string;
    name: string;
    idPrefix?: string;
    data: string;
    dropdownOptions: string[];
    editing: boolean;
    updating: boolean;
    editable?: boolean;
    onChangeCallback?: (newValue: string) => void;
    mandatory?: boolean;
    historyData?: FieldHistorySet | undefined;
    objectId?: string;
    source?: string;
    assetName?: string;
    dropdownDisplayNames?: string[];
}

interface InfoObjectDropdownPairProps {
    title: string;
    name: string;
    data: string;
    dropdownOptions: DropDownItem[];
    editing: boolean;
    updating: boolean;
    editable?: boolean;
    onChangeCallback?: (newValue: DropDownItem) => void;
    mandatory?: boolean;
    historyData?: FieldHistorySet | undefined;
    source?: string;
    assetName?: string;
    objectId?: string;
    disabled?: boolean;
    loading?: boolean;
}

interface EditButtonProps {
    requiredRoles: string[];
    editing: boolean;
    updating: boolean;
    enabled?: boolean;
}

interface InfoDatePickerPairProps {
    title: string;
    name: string;
    idPrefix?: string;
    data: Date;
    views: DateView[];
    editing: boolean;
    updating: boolean;
    editable?: boolean;
    mandatory?: boolean;
    historyData?: FieldHistorySet | undefined;
    objectId?: string;
    source?: string;
    assetName?: string;
}

export function BackButton({updating}: BackButtonProps): JSX.Element {
    const navigate = useNavigate();

    return(
        <Button
            style={{
                color: "#ffffff",
                backgroundColor: updating ? "#99b7c4" : "#0f334a" }}
            sx={{ margin: 1 }}
            onClick={() => {
                navigate(-1);
            }}
            disableRipple
            disabled={updating}
        >
            Back
        </Button>
    );
}

export function UploadStatusMessage({status}: UploadStatusMessageProps): JSX.Element {

    switch (status) {
        case "updating":
            return(
                <Alert severity="info" sx={{mb: 2}}>Updating asset data...</Alert>
            );
        case "adding":
            return(
                <Alert severity="info" sx={{mb: 2}}>Adding new asset data...</Alert>
            );
        case "success":
            return(
                <Alert severity="success" sx={{mb: 2}}>Asset data updated successfully</Alert>
            );
        case "added":
            return(
                <Alert severity="success" sx={{mb: 2}}>New asset added successfully</Alert>
            );
        case "failed":
            return(
                <Alert severity="error" sx={{mb: 2}}>Asset data could not be updated</Alert>
            );
        case "macError":
            return(
                <Alert severity="error" sx={{mb: 2}}>MAC address formatting is incorrect</Alert>
            );
        case "mandatory":
            return(
                <Alert severity="error" sx={{mb: 2}}>Fields marked with * are mandatory</Alert>
            );
        case "mac-already-exists":
            return(
                <Alert severity="error" sx={{mb: 2}}>Asset with this MAC address already exists</Alert>
            );
        case "host-already-exists":
            return(
                <Alert severity="error" sx={{mb: 2}}>Firewall with this host name already exists</Alert>
            );
        default:
            return(
                <Alert style={{display: "none"}} sx={{mb: 2}}>Hidden</Alert>
            );
    }
}

export function CancelButton({editing, updating, onClick}: CancelButtonProps): JSX.Element | null {

    if(!editing) return null;

    return(
        <Button
            sx={{ margin: 1 }}
            style={{
                color: "#ffffff",
                backgroundColor: updating ? "#99b7c4" : "#0f334a" }}
            variant="text"
            disableRipple
            onClick={onClick}
            disabled={updating}>
            Cancel
        </Button>
    );
}

interface HistoryBoxProps {
    open?: boolean;
    data?: FieldHistorySet | undefined;
    parentId: string;
    onClickOutside: () => void;
    openElem: HTMLElement | null;
    objectId?: string;
    source?: string;
    fieldName?: string;
    fieldHeader?: string;
    assetName?: string;
}

function HistoryBox(
    {
        open = false,
        data = undefined,
        parentId,
        onClickOutside,
        openElem,
        objectId,
        source,
        fieldName,
        fieldHeader,
        assetName,
    }: HistoryBoxProps): JSX.Element | null {
    if(data === undefined || objectId === "add") return null

    const navigate = useNavigate();
    const columnConfig = [
        {field: "value", headerName: "Value", minWidth: 80, flex: 2, editable: false, sortable: false},
        {field: "date", headerName: "Date", minWidth: 80, flex: 2, editable: false, sortable: false},
        {field: "version", headerName: "Version", minWidth: 80, flex: 1.5, editable: false, sortable: false},
        {field: "edited_by", headerName: "Edited By", minWidth: 80, flex: 3, editable: false, sortable: false},
    ]

    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, openElem, onClickOutside);

    const items = data.changes.length;
    const overFive = items > 5;
    const height = 64 + 53 * (overFive ? 5 : data.changes.length) + (overFive ? 0 : 30);

    const pos = document.getElementById(parentId)?.getBoundingClientRect();
    let rightPos: string | number = "auto";
    let leftPos: string | number = pos === undefined ? 0 : pos.left;
    let topPos: string | number = pos === undefined ? 0 : pos.top + 28;
    let bottomPos: string | number = "auto";

    if(pos !== undefined) {
        if(pos.left + 834 >= document.documentElement.getBoundingClientRect().right) {
            rightPos = 16;
            leftPos = "auto"
        }

        if(pos.top + height + 40 >= document.documentElement.getBoundingClientRect().bottom) {
            bottomPos = 16;
            topPos = "auto";
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClickOutside}
            sx={{
                ".MuiPaper-root": {
                    margin: 0,
                    minWidth: "800px",
                    maxWidth: "800px",
                },
                ".MuiDialog-scrollPaper": {
                    height: "auto",
                    position: "absolute",
                    right: rightPos,
                    left: leftPos,
                    top: topPos,
                    bottom: bottomPos,
                },
            }}
        >
            <DialogContent sx={{
                overflow: "hidden",
                paddingTop: "10px",
                minHeight: `${height - 50 + (overFive ? 60 : 0)}px`,
                maxHeight: `${height - 50 + (overFive ? 60 : 0)}px`,
            }}>
                <DataGrid
                    autoHeight
                    disableColumnMenu
                    columns={columnConfig}
                    rows={data.changes}
                    getRowId={(row: any) => row.version}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                    }
                    sx={{
                        '.MuiDataGrid-columnHeader': { fontWeight: "normal", fontSize: 16, backgroundColor: "#ffffff" },
                        '.MuiDataGrid-row.Mui-odd': { backgroundColor: "#f8f8f8" },
                        '.MuiDataGrid-row.Mui-even': { backgroundColor: "#ffffff" },
                        borderWidth: 0,
                    }}
                    style={{
                        height: `${height - 3}px`,
                        fontFamily: "Noto Sans",
                        fontSize: 16,
                        fontWeight: "lighter",
                        clear: "both",
                    }}
                    slots={{
                        pagination: null
                    }}
                    pageSizeOptions={[5]}
                    sortingOrder={['desc']}
                    hideFooter
                    initialState={{
                        sorting: {
                            sortModel: [{
                                field: "version",
                                sort: "desc",
                            }]
                        },
                        pagination: {
                            paginationModel: {
                                pageSize: 5
                            }
                        }
                    }}
                />
                {overFive &&
                    <Button
                        style={{bottom: 7}}
                        disableRipple
                        onClick={() => {
                            navigate({pathname: `/history/${source}/${objectId}+${assetName}+${fieldHeader}+${fieldName}`});
                        }}
                    >
                        Show More
                    </Button>
                }
            </DialogContent>
        </Dialog>
    )
}

const mandatoryMarker = <span style={{color: "#f80808"}}> *</span>

export const lastSeenWarningIcon = <Tooltip
    title={<Typography style={{fontSize: 12}}>This asset has not been seen in the last 3 months</Typography>}
    arrow
    disableInteractive
>
    <Box style={{marginLeft: 12}}>
        <img src="/img/triangle-exclamation-mark-default.svg" alt="last seen warn" width="22px" height="22px"/>
    </Box>
</Tooltip>

export function InfoFieldPair({
        title,
        name,
        idPrefix,
        data,
        multiline = false,
        editing,
        updating,
        editable = true,
        mandatory = false,
        historyData = undefined,
        objectId = undefined,
        source = undefined,
        assetName = undefined,
        showDateWarning = false,
}: InfoFieldPairProps): JSX.Element {
    const [historyOpen, setHistoryOpen] = React.useState(false);

    let value = "";

    if(title) {
        if(data) {
            value = data;
        }
        else {
            value = "-"
        }
    }

    const text = <Typography
        style={{
            fontSize: "medium",
            whiteSpace: "normal",
            wordWrap: "break-word"}}
        sx={{
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0}}>
        {value}
    </Typography>

    const input = <TextField
        id={idPrefix !== undefined ? `${idPrefix}${name}` : name}
        name={idPrefix !== undefined ? `${idPrefix}${name}` : name}
        variant="standard"
        size="small"
        label={multiline ? "Enter one item per line" : ""}
        multiline={multiline}
        sx={{marginBottom: 3}}
        defaultValue={data}
        disabled={updating}/>

    const field = editing && editable && name !== undefined ? input : text;

    const openHistoryElem = <HistoryIcon
        style={{height: "18px", width: "18px", position: "relative", top: "4px", left: "6px", cursor: "pointer"}}
        id={`history-icon-${name}`}
        onClick={() => {
            setHistoryOpen(!historyOpen);
        }}
    />

    const fieldTitle = <strong>
        {title}
        {(mandatory && editing && mandatoryMarker)}
        {!editing && historyData !== undefined && openHistoryElem}
    </strong>

    return <Box id={`ref-${name}`} style={{maxWidth: "25%", minWidth: "380px"}}>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        <Box>
            <Box style={{float: "left"}}>
                {field}
            </Box>
            <Box style={{float: "left"}}>
                {showDateWarning && lastSeenWarningIcon}
            </Box>
        </Box>
        <Box style={{clear: "both"}}>
            <HistoryBox
                open={historyOpen}
                data={historyData}
                parentId={`ref-${name}`}
                openElem={document.getElementById(`history-icon-${name}`)}
                onClickOutside={() => {
                    if(historyOpen) {
                        setHistoryOpen(false);
                    }
                }}
                objectId={objectId}
                source={source}
                fieldHeader={title}
                fieldName={name}
                assetName={assetName}
            />
        </Box>
        <br/>
    </Box>
}

export function InfoDropdownPair({
        title,
        name,
        idPrefix,
        data,
        dropdownOptions,
        editing,
        updating,
        editable = true,
        onChangeCallback,
        mandatory = false,
        historyData = undefined,
        objectId = undefined,
        source = undefined,
        assetName = undefined,
        dropdownDisplayNames = undefined,
}: InfoDropdownPairProps): JSX.Element {
    const [historyOpen, setHistoryOpen] = React.useState(false);
    const [selected, setSelected] = useState<string>(data);
    if (dropdownOptions.indexOf(selected) === -1) {
        setSelected(dropdownOptions[0]);
    }

    const text = <Typography
        style={{
            fontSize: "medium",
            whiteSpace: "normal",
            wordWrap: "break-word"}}
        sx={{
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0}}>
        {selected}
    </Typography>

    const dropdown = <Select
        id={idPrefix !== undefined ? `${idPrefix}${name}` : name}
        name={name}
        value={selected}
        disabled={updating}
        size="small"
        sx={{ minWidth: 150,
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0 }}
        onChange={(event: SelectChangeEvent<string>) => {
            setSelected(event.target.value);
            if(onChangeCallback !== undefined) {
                onChangeCallback(event.target.value);
            }
        }}
    >
        { /* Dropdown display items are expected to be in the same order and have same count as dropdown options */ }
        {dropdownOptions.map((option, index) => (
            <MenuItem key={option} value={option} style={{color: option === "" ? "#a0a0a0" : "#000000"}}>
                {dropdownDisplayNames === undefined && (option === "" ? "none" : option)}
                {dropdownDisplayNames !== undefined && dropdownDisplayNames[index]}
            </MenuItem >
          ))}
    </Select>

    const openHistoryElem = <HistoryIcon
        style={{height: "18px", width: "18px", position: "relative", top: "4px", left: "6px", cursor: "pointer"}}
        id={`history-icon-${name}`}
        onClick={() => {
            setHistoryOpen(!historyOpen);
        }}
    />

    const field = editing && editable ? dropdown : text;
    const fieldTitle = <strong>
        {title}
        {(mandatory && editing && mandatoryMarker)}
        {!editing && historyData !== undefined && openHistoryElem}
    </strong>

    return <Box id={`ref-${name}`} style={{maxWidth: "25%", minWidth: "380px"}}>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        {field}
        <HistoryBox
            open={historyOpen}
            data={historyData}
            parentId={`ref-${name}`}
            openElem={document.getElementById(`history-icon-${name}`)}
            onClickOutside={() => {
                if(historyOpen) {
                    setHistoryOpen(false);
                }
            }}
            objectId={objectId}
            source={source}
            fieldHeader={title}
            fieldName={name}
            assetName={assetName}
        />
        <br/>
    </Box>
}


const containsText = (text: string, search: string): boolean =>
    text.toLowerCase().indexOf(search.toLowerCase()) > -1;


const MenuProps = {
    autoFocus: false,
    PaperProps: { sx: { maxHeight: 300, maxWidth: 400 } },
    sx: {
        '& .MuiMenuItem-root': {
            fontSize: 16,
            fontWeight: "regular",
        },
    }
};

export function InfoObjectDropdownPair({
        title,
        name,
        data,
        dropdownOptions,
        editing,
        updating,
        editable = true,
        onChangeCallback,
        mandatory = false,
        historyData = undefined,
        source = undefined,
        assetName = undefined,
        objectId = undefined,
        disabled = false,
        loading = false
}: InfoObjectDropdownPairProps): JSX.Element {
    const [historyOpen, setHistoryOpen] = React.useState(false);

    const openHistoryElem = <HistoryIcon
        style={{height: "18px", width: "18px", position: "relative", top: "4px", left: "6px", cursor: "pointer"}}
        id={`history-icon-${name}`}
        onClick={() => {
            setHistoryOpen(!historyOpen);
        }}
    />

    const fieldTitle = <strong>
        {title}
        {(mandatory && editing && mandatoryMarker)}
        {!editing && historyData !== undefined && openHistoryElem}
    </strong>

    if (dropdownOptions.length === 0 && !loading && !disabled) {
        return <Box>
        <Typography style={{fontSize: "medium", maxWidth: "25%", minWidth: "380px"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        <Typography
            style={{
                fontSize: "medium",
                whiteSpace: "normal",
                wordWrap: "break-word"}}
            sx={{
                marginTop: 0,
                marginBottom: 1.4,
                marginLeft: 0,
                marginRight: 0,
                padding: 0}}>
            {data}
        </Typography>
        <br/>
        <HistoryBox
            open={historyOpen}
            data={historyData}
            parentId={`ref-${name}`}
            openElem={document.getElementById(`history-icon-${name}`)}
            onClickOutside={() => {
                if(historyOpen) {
                    setHistoryOpen(false);
                }
            }}
            objectId={objectId}
            source={source}
            fieldHeader={title}
            fieldName={name}
            assetName={assetName}
        />
    </Box>
    }

    const index = dropdownOptions.findIndex(option => option.name === data);
    const defaultItem: DropDownItem = {
        name: data,
        id: -1
      };
    const selectedItem = index === -1 ? defaultItem : dropdownOptions[index];
    const [selected, setSelected] = useState<DropDownItem>(selectedItem);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        setSelected(selectedItem);
    }, [dropdownOptions])

    const displayedItems = useMemo(
        () => dropdownOptions.filter((option) => containsText(option.name, searchText)),
        [dropdownOptions, searchText]
        );

    const text = <Typography
        style={{
            fontSize: "medium",
            whiteSpace: "normal",
            wordWrap: "break-word"}}
        sx={{
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0}}>
        {data}
    </Typography>

    const dropdown = <Select
        MenuProps={MenuProps}
        id={name}
        name={name}
        value={displayedItems.includes(selected) ? selected : ""}
        disabled={updating || disabled}
        size="small"
        renderValue={() => selected.name}
        sx={{ minWidth: 200,
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0 }}
        onChange={(event: SelectChangeEvent<DropDownItem>) => {
            const item = dropdownOptions.find(option => option.name === event.target.value);
            if (item) {
                setSelected(item);
                if(onChangeCallback !== undefined) {
                    onChangeCallback(item);
                }
            }

        }}
        onClose={() => setSearchText("")}
    >
        <ListSubheader>
            <TextField
                size="small"
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start"/>
                )
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                }
                }}
            />
        </ListSubheader>
        {displayedItems.map((option) => (
            <MenuItem key={option.id} value={option.name} style={{color: option.name === "" ? "#a0a0a0" : "#000000"}}>
              {option.name === "" ? "none" : option.name}
            </MenuItem >
          ))}
    </Select>

    const circular = <CircularProgress size="32px" style={{marginBottom: "14px"}}/>

    let field = text;

    if(editing && editable) {
        if(loading) {
            field = circular;
        }
        else {
            field = dropdown;
        }
    }

    return <Box style={{maxWidth: "25%", minWidth: "380px"}}>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        {field}
        <br/>
    </Box>
}

export function EditButton({requiredRoles, editing, updating, enabled}: EditButtonProps): JSX.Element | null {
    if(AuthenticateRolesLocally(requiredRoles) !== RoleAuthResult.RoleFound) {
        return null;
    }

    return (
        <Button
            sx={{ margin: 1 }}
            style={{
                color: "#ffffff",
                backgroundColor: updating || !enabled ? "#99b7c4" : "#0f334a" }}
            variant="text"
            type="submit"
            disableRipple
            disabled={updating || !enabled}>
            { editing ? "Save" : "Edit" }
        </Button>
    );
}

export function InfoDatePickerPair({
    title,
    name,
    idPrefix,
    data,
    views,
    editing,
    updating,
    editable = true,
    mandatory = false,
    historyData = undefined,
    objectId = undefined,
    source = undefined,
    assetName = undefined,
}: InfoDatePickerPairProps): JSX.Element {
    const [historyOpen, setHistoryOpen] = React.useState(false);

    let value = new Date()

    if(title) {
        if(data) {
            value = new Date(data);
        }
        else {
            value = new Date();
        }
    }

    const text = <Typography
        style={{
            fontSize: "medium",
            whiteSpace: "normal",
            wordWrap: "break-word"}}
        sx={{
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0}}>
        {dayjs(data).format("MMMM YYYY")}
    </Typography>

    const input = <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
            views={views}
            value={dayjs(value)}
            label={idPrefix !== undefined ? `${idPrefix}${name}` : name}
            name={idPrefix !== undefined ? `${idPrefix}${name}` : name}
            disabled={updating}/>
        </LocalizationProvider>

    const field = editing && editable && name !== undefined ? input : text;

    const openHistoryElem = <HistoryIcon
        style={{height: "18px", width: "18px", position: "relative", top: "4px", left: "6px", cursor: "pointer"}}
        id={`history-icon-${name}`}
        onClick={() => {
            setHistoryOpen(!historyOpen);
        }}
    />

    const fieldTitle = <strong>
        {title}
        {(mandatory && editing && mandatoryMarker)}
        {!editing && historyData !== undefined && openHistoryElem}
    </strong>

    return <Box id={`ref-${name}`} style={{maxWidth: "25%", minWidth: "380px"}}>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        <Box>
            <Box style={{float: "left"}}>
                {field}
            </Box>
        </Box>
        <Box style={{clear: "both"}}>
            <HistoryBox
                open={historyOpen}
                data={historyData}
                parentId={`ref-${name}`}
                openElem={document.getElementById(`history-icon-${name}`)}
                onClickOutside={() => {
                    if(historyOpen) {
                        setHistoryOpen(false);
                    }
                }}
                objectId={objectId}
                source={source}
                fieldHeader={title}
                fieldName={name}
                assetName={assetName}
            />
        </Box>
        <br/>
    </Box>
  }
